import React, { useEffect, FC } from 'react';

const ErrorFallback: FC = ({ error }: any) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      sessionStorage.getItem(
        'page-has-been-force-refreshed',
      ) || 'false',
    );
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        sessionStorage.setItem(
          'page-has-been-force-refreshed',
          'true',
        );
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div>
      <p>Something went wrong.</p>
      <pre>{error?.message}</pre>
    </div>
  );
};

export default ErrorFallback;
