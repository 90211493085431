import _ from 'lodash';
import configs from 'configs';
import { message } from 'antd';
import { CURRENT_LANGUAGE } from '@/i18n';

// eslint-disable-next-line import/prefer-default-export
export function environment(env: string) {
  if (env) {
    return process.env.REACT_APP_ENV === env;
  }

  return process.env.REACT_APP_ENV;
}

/**
 * 过滤对象中的假值
 * @param object
 */
export function removeObjectFalsyKey<T extends Object>(object: T) {
  return _.pickBy(object, _.identity);
}

/**
 * 过滤对象中的 NULL/undefined
 * @param object
 */
export function removeObjectNil<T extends Object>(object: T) {
  return _.omitBy(object, _.isNil);
}

export function stayPageBeaconUpdate(beacon: any) {
  let info;
  if (localStorage.user) {
    info = JSON.parse(localStorage.user);
  }
  const preBeaconStayKey = sessionStorage.getItem('beacon-stay-key');
  const enterTime = sessionStorage.getItem('enter_time');
  const curTime = new Date().getTime();
  const beaconStayTimeKey = window.location.pathname;
  const curBeaconStayKey = configs(`beacon.${beaconStayTimeKey}`);
  if (preBeaconStayKey && enterTime && preBeaconStayKey !== curBeaconStayKey) {
    beacon.onDirectUserAction(preBeaconStayKey,
      removeObjectFalsyKey({
        stay_time: String(curTime - (+enterTime)),
        // eslint-disable-next-line camelcase
        user_id: info?.email,
      }));
    sessionStorage.setItem('beacon-stay-key', curBeaconStayKey);
    sessionStorage.setItem('enter_time', `${curTime}`);
  } else if (!preBeaconStayKey || !enterTime) {
    sessionStorage.setItem('beacon-stay-key', curBeaconStayKey);
    sessionStorage.setItem('enter_time', `${curTime}`);
  }
}

export function isZHLanguage(): boolean {
  return CURRENT_LANGUAGE === 'zh';
}

export function closePage() {
  if (window.opener) {
    window.opener = null;
    window.open('', '_self');
    window.close();
  } else {
    message.error('暂时不支持关闭当前页面!');
  }
}

interface JsonPayload {
  [key: string]: any
}

export function transformJsonToFormData(payload: JsonPayload): FormData {
  const formData = new FormData();
  Object.keys(payload).forEach((key: string) => {
    formData.append(key, payload[key]);
  });

  return formData;
}
