import React, { FC, lazy, Suspense } from 'react';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import configs from 'configs';
import { SuspenseFallback, ErrorFallback } from '@/components';
import history from '@/shared/history';
import './i18n';
import { BeaconContext, UserInfoContext } from '@/context';
import { ErrorBoundary } from 'react-error-boundary';

const globalAntdConfigs = configs('antd');
const Admin = lazy(() => import('./pages/Admin'));
const Account = lazy(() => import('./pages/Account'));

const App: FC = () => (
  <Router history={history}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<SuspenseFallback />}>
        <ConfigProvider {...globalAntdConfigs}>
          <BeaconContext.Provider>
            <UserInfoContext.Provider>
              <Switch>
                <Route path="/admin" component={Admin} />
                <Route path="/account" component={Account} />
                <Redirect from="/" to="admin" />
              </Switch>
            </UserInfoContext.Provider>
          </BeaconContext.Provider>
        </ConfigProvider>
      </Suspense>
    </ErrorBoundary>
  </Router>
);

export default App;
