import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import configs from 'configs';
import { environment } from '@/shared/helper';
import App from './app';

import 'assets/styles/core.less';

if (!environment('local')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: configs('app.serverEnv'),
    release: process.env.REACT_APP_RELEASE_VERSION,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      'Non-Error promise rejection captured with keys: errors',
    ],
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);
