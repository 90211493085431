const HOME_PATH = '/account/login';
const ADMIN_COMPANY_LIST_PATH = '/admin/company/list';
const ADMIN_USER_LIST_PATH = '/admin/user/list';
const TERMS_PATH = '/account/terms';

export default {
  [HOME_PATH]: 'stay_time_sso_login_page',
  [ADMIN_COMPANY_LIST_PATH]: 'stay_time_sso_company_list_page',
  [ADMIN_USER_LIST_PATH]: 'stay_time_sso_user_list_page',
  [TERMS_PATH]: 'stay_time_terms_page',
};
