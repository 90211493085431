import { createContainer } from 'unstated-next';
import { useEffect, useState } from 'react';
import { User } from '@/api/Users';
import Beacon from './Beacon';

function useUserInfo() {
  const { beacon } = Beacon.useContainer();
  const [info, setInfo] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (localStorage.user) {
      setInfo(JSON.parse(localStorage.user));
    }
  }, []);

  useEffect(() => {
    if (info) {
      beacon.setOpenId(info.email);
    }
  }, [beacon, info]);

  return {
    info,
    setInfo,
  };
}

const UserInfo = createContainer(useUserInfo);

export default UserInfo;
