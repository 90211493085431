export default {
  env: process.env.NODE_ENV,

  server: process.env.REACT_APP_SERVER,

  // 服务器环境（测试、本地、生产）
  serverEnv: process.env.REACT_APP_ENV,

  beaconAppKey: process.env.REACT_APP_BEACON_APP_KEY,

  ioaSite: process.env.REACT_APP_IOA_SITE,

  supportedLanguages: [
    { key: 'en', name: 'English' },
    { key: 'zh', name: '中文' },
  ],

  tencentCaptchaAppId: process.env.REACT_APP_TENCENT_CAPTCHA_APP_ID,

  iconScriptUrl: '//at.alicdn.com/t/font_2677545_hodwz1b911.js',
};
