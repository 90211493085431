const DISABLE_TYPE = 0;
const ENABLE_TYPE = 1;
const USER_TYPE = 0;
const ADMIN_TYPE = 1;
const SUPER_ADMIN_TYPE = 2;

export default {
  disableType: DISABLE_TYPE,
  enableType: ENABLE_TYPE,
  userType: USER_TYPE,
  adminType: ADMIN_TYPE,
  superAdminType: SUPER_ADMIN_TYPE,
};
