import React, { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { changeLanguage, CURRENT_LANGUAGE } from '@/i18n';
import configs from 'configs';
import _ from 'lodash';

interface LangMap {
  [key: string]: {
    key: string,
    name: string,
  }
}

interface TranslationLanguageProps {
  className?: string,
}

const supportedLanguages = configs('app.supportedLanguages');
const langMap: LangMap = _.keyBy(supportedLanguages, 'key');

const TranslationLanguage: FC<TranslationLanguageProps> = ({ className }: TranslationLanguageProps) => {
  const handleLanguageChange = (data: any) => {
    changeLanguage(data.key);
    window.location.reload();
  };
  const languageTranslationMenu = (
    <Menu onClick={handleLanguageChange}>
      {supportedLanguages.map((item: any) => (
        <Menu.Item key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      overlay={languageTranslationMenu} className={className}
      trigger={['click']}
    >
      <div>
        {langMap[CURRENT_LANGUAGE]?.name}
        <CaretDownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
      </div>
    </Dropdown>
  );
};

export default TranslationLanguage;
